<template>
  <img src="../../assets/icons/hamburgerIcon.png" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HamburgerButton",
});
</script>

<style scoped>
img {
  width: 40px;
}
</style>
