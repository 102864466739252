
import { defineComponent } from "vue";
import Game from "./Game.vue";

const TIME_UNTIL_POPUP = 30;
const START_GAME_TEXT = "birdsarecool";

interface StartGameData {
  activeTime: number;
  countdown: number;
  gameStarted: boolean;
  showPopup: boolean;
  popupDismissed: boolean;
  userEnteredText: string;
}

export default defineComponent({
  name: "StartGamePopup",
  components: { Game },
  data(): StartGameData {
    return {
      activeTime: 0,
      countdown: 5,
      gameStarted: false,
      showPopup: false,
      popupDismissed: false,
      userEnteredText: "",
    };
  },
  methods: {
    startTimer() {
      setInterval(this.increaseTime, 1000);
    },
    increaseTime() {
      this.activeTime++;
      if (this.activeTime > TIME_UNTIL_POPUP) {
        this.showPopup = true;
      }
    },
    startGame() {
      this.countdown = 5;
      this.gameStarted = true;
    },
    reduceTime() {
      if (this.countdown > 0) {
        this.countdown -= 1;
      }
    },
    hideAndEndGame() {
      this.showPopup = false;
      this.popupDismissed = true;
      this.gameStarted = false;
      this.userEnteredText = "";
    },
    handleUserEnteredText() {
      window.addEventListener("keydown", (e) => {
        this.userEnteredText += e.key;
        if (this.userEnteredText.includes(START_GAME_TEXT)) {
          this.showPopup = true;
          this.popupDismissed = false;
        }
      });
    },
  },
  mounted() {
    this.startTimer();
    this.handleUserEnteredText();
    setInterval(this.reduceTime, 1000);
  },
});
