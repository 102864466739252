
import { defineComponent } from "vue";
import { imageContent } from "../../photography-content";

export default defineComponent({
  name: "Sidebar",
  props: { activeCategory: String },
  data() {
    return {
      navigationItems: imageContent,
      activeItem: this.activeCategory,
    };
  },
  watch: {
    activeCategory(newCategory) {
      this.activeItem = newCategory;
    },
  },
  methods: {
    onClickCategory(item: string) {
      this.$emit("handle-item-click", item);
    },
    scroll() {
      const list = document.getElementById("list");
      list.scrollTop += 20;
    },
  },
});
