
import { defineComponent } from "vue";

export default defineComponent({
  name: "Price",
  props: { priceUrl: String },
  data() {
    return {
      url: this.priceUrl,
    };
  },
});
