
import { defineComponent } from "vue";
import {
  prices, emojis, animalIds,
} from "./constants";
import Price from "./Price.vue";
import { generateRandomNumberFromInterval } from "../../helpers/utilities";
import { calculateNewOffset, calculateSpeed, calculateNewPosition } from "./helpers";

interface Emoji {
  emoji: string;
  id: string;
}

interface GameData {
  firstIteration: boolean;
  emojis: Emoji[];
  clickedBadThings: [];
  savedAnimals: [];
  lifes: number;
  hasLost: boolean;
  hasWon: boolean;
  time: number;
  price: string;
  tabKey: number;
}

export default defineComponent({
  name: "Game",
  components: { Price },

  data(): GameData {
    return {
      emojis: window.innerWidth < 800 ? emojis.slice(0, emojis.length - 5) : emojis,
      firstIteration: true,
      clickedBadThings: [],
      savedAnimals: [],
      lifes: 2,
      hasLost: false,
      hasWon: false,
      time: 60,
      price: "",
      tabKey: 0,
    };
  },
  props: { onClose: Function },
  methods: {
    transformEmojis(element) {
      element.style.fontSize = `${generateRandomNumberFromInterval(16, 35)}px`;

      const newOffset = calculateNewOffset();
      const top = element.offsetTop;
      const left = element.offsetLeft;
      const speed = calculateSpeed({ top, left }, newOffset);
      const newPosition = calculateNewPosition(newOffset);

      if (Math.random() > 0.2 || this.firstIteration) {
        element.animate(
          [
            { transform: `translateX(${newPosition.x})` },
            { transform: `translateY(${newPosition.y})` },
            { transform: "rotate(180deg)" },
          ],
          {
            duration: speed,
          },
        );
      }

      setTimeout(() => {
        element.style.left = `${newOffset.left}px`;
        element.style.top = `${newOffset.top}px`;
        if (!this.hasLost && !this.hasWon) {
          this.transformEmojis(element);
        }
      }, speed - 10);
    },
    handleClick(emojiData: Emoji) {
      if (this.hasWon || this.hasLost) {
        return;
      }
      const animalClicked = animalIds.includes(emojiData.id);
      const clickedEmoji = document.getElementById(emojiData.id);
      clickedEmoji.style.fontSize = "100px";

      setTimeout(() => {
        if (animalClicked) {
          if (this.savedAnimals.includes(emojiData.emoji)) {
            return;
          }
          // eslint-disable-next-line no-alert
          alert(`${emojiData.id} saved`);
          this.savedAnimals.push(emojiData.emoji);
          clickedEmoji.style.display = "none";
        } else {
          if (this.clickedBadThings.includes(emojiData.emoji)) {
            return;
          }
          // eslint-disable-next-line no-alert
          alert(
            `You touched the dangerous ${emojiData.id}. You lost one life.`,
          );
          clickedEmoji.style.fontSize = "20px";
          this.clickedBadThings.push(emojiData.emoji);
          this.lifes -= 1;
        }

        if (this.lifes === 0) {
          this.hasLost = true;
        }
        if (this.savedAnimals.length === 6) {
          this.hasWon = true;
        }
      }, 400);
    },
    startGame() {
      setTimeout(() => {
        this.firstIteration = false;
      }, 1000);
      document.querySelectorAll(".emoji").forEach((element) => {
        this.transformEmojis(element);
      });
    },
    resetAndStart() {
      this.firstIteration = true;
      this.savedAnimals = [];
      this.clickedBadThings = [];
      this.lifes = 2;
      this.hasLost = false;
      this.hasWon = false;
      this.time = 60;
      this.price = "";
      this.tabKey += 1;
      animalIds.forEach((id) => {
        document.getElementById(id).style.display = "block";
      });
      this.startGame();
    },
    reduceTime() {
      if (!this.hasLost && !this.hasWon) {
        this.time -= 1;
      }
      if (this.time === 0) {
        this.hasLost = true;
      }
    },
    setPrice() {
      if (!this.price) {
        this.price = prices[Math.floor(Math.random() * prices.length)];
      }
    },
    closeGame() {
      this.onClose();
    },
  },
  mounted() {
    document.querySelector("body").style.overflowY = "hidden";
    setInterval(this.reduceTime, 1000);
    this.startGame();
  },
  beforeUnmount() {
    document.querySelector("body").style.overflowY = "auto";
  },
});
