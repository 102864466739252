
import { defineComponent } from "vue";

import Sidebar from "./Sidebar.vue";
import Loader from "../common/Loader.vue";
import { imageContent } from "../../photography-content";
import { getRandomImages } from "../../helpers/utilities";

interface ImageViewData {
  activeCategory: string;
  allImages: string[];
  shownImagesBlocks: string[][];
  maxImagesReached: boolean;
  isLoading: boolean;
  numberImagesLoaded: number;
  isMobile: boolean;
  mobileCategoriesOpen: boolean;
  scrollYPosition: number;
  currentOpenedImage: string;
}

const numberImageBlock = 10;
const randomCategory = "random";

export default defineComponent({
  name: "ImageView",
  data(): ImageViewData {
    return {
      activeCategory: randomCategory,
      allImages: [],
      shownImagesBlocks: [],
      maxImagesReached: false,
      numberImagesLoaded: 0,
      isLoading: false,
      isMobile: window.innerWidth < 800,
      mobileCategoriesOpen: false,
      scrollYPosition: 0,
      currentOpenedImage: null,
    };
  },
  components: { Sidebar, Loader },
  created() {
    if (!this.shownImagesBlocks.length) {
      this.isLoading = true;

      if (
        this.$route.query.category
        && imageContent[this.$route.query.category]
      ) {
        this.allImages = imageContent[this.$route.query.category].paths;
        this.activeCategory = this.$route.query.category;
      } else {
        const randomImages = getRandomImages();
        this.allImages = randomImages;
        this.shownImagesBlocks.push(randomImages.slice(0, numberImageBlock));
      }

      this.shownImagesBlocks = [this.allImages.slice(0, numberImageBlock)];
    }
  },
  methods: {
    onItemClick(clickedCategory: keyof typeof imageContent | "random") {
      if (this.activeCategory === clickedCategory) return;
      this.isLoading = true;
      this.$router.push({ query: { category: clickedCategory } });
      this.maxImagesReached = false;
      this.numberImagesLoaded = 0;

      if (clickedCategory === randomCategory) {
        const randomImages = getRandomImages();
        this.allImages = randomImages;
        this.shownImagesBlocks = [randomImages.slice(0, numberImageBlock)];
      } else {
        this.allImages = imageContent[clickedCategory].paths;
        this.shownImagesBlocks = [this.allImages.slice(0, numberImageBlock)];
      }
      if (this.mobileCategoriesOpen) {
        this.toggleOpenCategories();
      }

      this.activeCategory = clickedCategory;
      window.scrollTo(0, 0);
    },
    toggleOpenCategories() {
      this.mobileCategoriesOpen = !this.mobileCategoriesOpen;
      if (this.mobileCategoriesOpen) {
        document.querySelector("body").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.overflowY = "auto";
      }
    },
    onImageLoad() {
      this.numberImagesLoaded++;
    },
    checkIfBlockLoaded(blockIndex: number): boolean {
      // We just check for the last block as the blocks before should be already loaded
      if (blockIndex !== this.shownImagesBlocks.length - 1) {
        return true;
      }
      let numberImagesToShow = 0;
      this.shownImagesBlocks.forEach((imageBlock) => {
        numberImagesToShow += imageBlock.length;
      });
      if (this.numberImagesLoaded === numberImagesToShow) {
        this.isLoading = false;
        return true;
      }
      return false;
    },
    hasScrolledIntoElement(el: any): boolean {
      const rect = el.getBoundingClientRect();
      const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
      return isVisible;
    },
    onScroll() {
      // needed for position of categories overlay on mobile
      this.scrollYPosition = window.scrollY;
      // needed for load more logic
      const scrolledTo = document.querySelector(".load-more-identifier");
      if (this.hasScrolledIntoElement(scrolledTo) && !this.isLoading) {
        this.isLoading = true;
        setTimeout(() => {
          this.showMore();
        }, 500);
      }
    },
    showMore() {
      let numberCurrentlyShown = 0;
      this.shownImagesBlocks.forEach((imageBlock) => {
        numberCurrentlyShown += imageBlock.length;
      });
      const numberNextShown = numberCurrentlyShown + numberImageBlock;
      const maxShown = this.allImages.length;
      let newImages;
      if (numberNextShown >= maxShown) {
        newImages = this.allImages.slice(numberCurrentlyShown, maxShown);
        this.maxImagesReached = true;
      } else {
        newImages = this.allImages.slice(numberCurrentlyShown, numberNextShown);
      }
      this.shownImagesBlocks.push(newImages);
    },
    onResize() {
      this.isMobile = window.innerWidth < 800;
    },
    showImageInPopup(path: string) {
      this.currentOpenedImage = path;
      if (path) {
        document.querySelector("body").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.overflowY = "auto";
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("scroll", this.onScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.onResize);
  },
});
