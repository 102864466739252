
import { defineComponent } from "vue";

export default defineComponent({
  name: "Navigation",
  props: { activeItem: String },
  data() {
    return {
      activePage: this.activeItem,
    };
  },
  watch: {
    activeItem(newActivePage) {
      this.activePage = newActivePage;
    },
  },
  methods: {
    handleMenuClick(item: string) {
      this.$emit("on-menu-click", item);
    },
  },
});
