
import { defineComponent } from "vue";
import SocialMedia from "./SocialMedia.vue";
import HamburgerButton from "./HamburgerButton.vue";
import Navigation from "./Navigation.vue";

export default defineComponent({
  name: "Topbar",
  components: { SocialMedia, HamburgerButton, Navigation },
  data() {
    return {
      isMobile: window.innerWidth < 800,
      menuOpen: false,
      activePage: window.location.pathname,
    };
  },
  created() {
    this.activePage = window.location.hash.substring(1);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 800;
    },
    showMenu() {
      document.querySelector("body").style.overflowY = "hidden";
      this.menuOpen = true;
    },
    onHideMenu() {
      document.querySelector("body").style.overflowY = "auto";
      this.menuOpen = false;
    },
    handleMenuClick(item: string) {
      this.activePage = item;
      this.onHideMenu();
      this.$router.push(item);
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  destroy() {
    window.removeEventListener("resize", this.onResize);
  },
});
