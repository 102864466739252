
import { defineComponent } from "vue";
import Topbar from "./Topbar/Topbar.vue";
import Fun from "./Game/StartGamePopup.vue";

export default defineComponent({
  name: "Main",
  components: { Topbar, Fun },
  data() {
    return {
      componentKey: 0,
    };
  },
  methods: {
    onClickImprint() {
      this.$router.push("imprint");
    },
  },
  mounted() {
    window.onpopstate = () => {
      this.componentKey += 1;
      this.$forceUpdate();
    };
  },
});
